
import { Typography } from '@mui/material';

import { Chart2vixcurve } from "./Charts/ChartComponents2vixcurve.js";

import { Chart2vts } from "./Charts/ChartComponents2vts.js";
import Chart2VTdesc from "./Info/Chart2VTdesc.js"; 

import { Chart2 } from "./Charts/ChartComponents2.js";
import DisplayChart2Data from "./Info/Displaylatestdata2.js"; 
import Chart2desc from "./Info/Chart2desc.js"; 

//todo:
//import Get2Percentage from "./Info/Get4Percentage2.js"; 



{/* 
comments 
*/}

{/* 

        <div style={{id:"chart2-get4pct", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>

          aa Get4Percentage2 /aa 
          
        </div>

*/}

//<Chart2VTS chartName="VIX Term structure" />


const PageC2ZSC = () => {
return (
    

    <div id="PageZSC">
        
        <div style={{id:"chart2title", textAlign: "Left",  textDecoration: "underline", paddingLeft: "10%" }}>
                
            <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 12, sm: 14, md: 16, lg: 18 } }}>
                
                2. Chart - VIX Futures Curve and Term Structure
                
            </Typography>
                
         </div>

        <div style={{id:"chart2Vixcurve", display: "flex", margin: "auto", alignItems: "center", justifyContent: "center"}}>

            <Chart2vixcurve chartName="VIX Futures Curve" />
     
        </div>

        <br />

        <div style={{id:"chart2a", display: "flex", margin: "auto", alignItems: "center", justifyContent: "center"}}>

            <Chart2vts chartName="VIX Term structure" />

        </div>
        
        <div style={{id:"chart2-desc", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>
        
            <Chart2VTdesc />

        </div>
  
        <div style={{id:"chart2btitle", textAlign: "Left",  textDecoration: "underline", paddingLeft: "10%" }}>
            
            <Typography sx={{ fontFamily: "Merriweather", fontWeight: "bold", fontSize: { xs: 12, sm: 14, md: 16, lg: 18 } }}>
            
            2b. Chart - Relative mean deviation for each VIX spreads
            
            </Typography>
            

        </div>
        
        <div style={{id:"chart2zscore", display: "flex", margin: "auto", alignItems: "center", justifyContent: "center"}}>
            <Chart2 chartName="VIX Zscore comparison" />
        </div>
        
        <br/>
        <br/>
        
        <div style={{id:"chart2-updatedvalue", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>

            <DisplayChart2Data />
            
        </div>
        
        <br/>

        <div style={{id:"chart2-desc", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center"}}>
        
            <Chart2desc />

        </div>
        <br/>
        <br/>
        
        
        <br/>
        <br/>

    </div>
  
  );
};
export default PageC2ZSC;
