import Typography from '@mui/material/Typography';


function Chart2VTdesc() {
  return (

    <table style={{ borderCollapse: 'collapse', width: '85%', height: '90px' }} >
        <tbody>
        <tr>
        <td style={{ width: '25%' }} >
        
        <div style={{id:"chart2VTdesc", textAlign: "Left", justifyContent: "center" }}>
            <br />  
              <Typography sx={{ fontFamily: "Merriweather", fontSize: { xs: 12, sm: 13, md: 14, lg: 15  } }}textAlign="justify">
              
              <span style={{ fontWeight: "bold" }}>What the 2 charts represent: </span> 
              
               The VIX futures curve shows the prices across the different VIX contracts, while the term structure shows the prices based on expected S&P 500 index volatility over various time periods. The shape of the VIX Futures curve and Term structure can indicate the market’s sentiment about future volatility and risk. 
               
               <br />
               <br />
               
               VIX contango means that the curve is upward sloping, meaning that longer-term VIX  are more expensive than shorter-term ones. Contango is the normal state of the VIX curve, as it reflects the mean-reverting nature of volatility and uncertainty.   
               
               <br />
               <br />
               
               VIX backwardation means that the curve is downward sloping, meaning that shorter-term VIX are more expensive than longer-term ones. Backwardation is an uncommon state of the VIX curve, as it usually occurs when the spot VIX index spikes due to a sudden market shock or crisis.
               

               <br />
               <br />
               {'\u27A2'} &nbsp;  For example, when the VIX curve is in steep contango, it could be a sign of confidence or complacency among investors, and a potential opportunity to buy volatility protection at a lower cost. On the other hand, when the VIX curve is in steep backwardation, it could be a sign of fear or panic among investors, and a potential opportunity to sell volatility protection at a higher premium.
               
               <br />
               <br />
               {'\u27A2'} &nbsp;  By analyzing the VIX curve, traders and investors can gain insights into the market’s expectations and adjust their strategies accordingly
              
               <br />
               <br />
               <span style={{ fontStyle: 'italic' }}>
                  *Pls refer to official market data for real time prices.
               </span>
               
              <br />
              <br />
              
              </Typography>
            <br/>
        </div>
        
        </td>
        </tr> 
        </tbody>
      </table>

  );
}
export default Chart2VTdesc;
