// import React and Chart from the react-google-charts library
import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import Papa from 'papaparse'; // Import Papa Parse
//import { ResponsiveContainer } from 'recharts';



{/* 
comments 
*/}
// in a file named utils.js
export function getMonthName(month) {
  // create an array to store the month names
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  // return the name of the month
  return months[month - 1];
}

// define a function that takes a chart name as a parameter

export const Chart2vixcurve = (chartName) => {
  // use useState hook to store the data for the chart
  const [data, setData] = useState([]);
  // define a function that fetches the data from the json files
  
  // create state variables for the records
  const [record0day, setRecord0day] = useState({});
  const [record1day, setRecord1day] = useState({});
  const [record2day, setRecord2day] = useState({});
  const [record5day, setRecord5day] = useState({});
  const [record10day, setRecord10day] = useState({});
  
  
  const fetchData = async () => {
    try {
        const response = await fetch("./VIXFuturesAll.csv",{ method: 'GET', responseType: 'text' });
        
        const csvData = await response.text();

        //#results
        const results = Papa.parse (csvData, { header: true });
        

        //console.table (results.data);
        const record0day = results.data[results.data.length - 2];
        const record1day = results.data[results.data.length - 3];  
        const record2day = results.data[results.data.length - 4];  
        const record5day = results.data[results.data.length - 7];  
        const record10day = results.data[results.data.length - 12];  
        
        //console.log(record0day);
        //console.log(record1day);
        //console.log(record2day);
        //console.log(record5day);
        //console.log(record10day); 

        // set the state with the records
        setRecord0day(record0day);
        setRecord1day(record1day);
        setRecord2day(record2day);
        setRecord5day(record5day);
        setRecord10day(record10day);        

        //console.log(getMonthName(record0day["expirationMth1"]));
        //console.log(getMonthName(record0day["expirationMth2"]));
        //console.log(getMonthName(record0day["expirationMth3"]));      
        //console.log(getMonthName(record0day["expirationMth4"]));      
        //console.log(getMonthName(record0day["expirationMth5"]));
        

        const M1Exp10day = getMonthName(record10day["expirationMth1"]);
        //const M1Exp5day = getMonthName(record10day["expirationMth1"]);
        //const M1Exp2day = getMonthName(record10day["expirationMth1"]);        
        
        const M1Exp0day = getMonthName(record0day["expirationMth1"]);
        const M2Exp0day = getMonthName(record0day["expirationMth2"]);
        const M3Exp0day = getMonthName(record0day["expirationMth3"]);      
        const M4Exp0day = getMonthName(record0day["expirationMth4"]);      
        const M5Exp0day = getMonthName(record0day["expirationMth5"]);
        const M6Exp0day = getMonthName(record0day["expirationMth6"]);

        /*
        //Sample working chartData:
     
        const chartData = [
          ["VIXcontracts","10d","5d", "2d","1d","Today"], // labels for the axes and the series
          ["Dec", 14, 13, 12.5, 12, null], // point #0         
          ["Jan", 14, 13, 12.5, 12, 11.67], // first point
          ["Feb", 15, 14, 13.8, 13.5, 13.43], // second point
          ["Mar", 15.5, 14.6, 14.3,14, 14.58], // third point
          ["Apr", 17, 16.2, 15.8,15.5, 15.76], // fourth point
          ["May", 17.5, 17, 16.4,16.5, 16.55], // fifth point

        ];
        */
        
        const NumM1Exp0day = Number(record0day["expirationMth1"]);
        const NumM1Exp2day = Number(record2day["expirationMth1"]);
        const NumM1Exp5day = Number(record5day["expirationMth1"]);
        const NumM1Exp10day = Number(record10day["expirationMth1"]);
        
        
        //console.log(NumM1Exp0day + 10);
        //console.log(NumM1Exp10day + 10);
        
        
        var chartData = [
          ["VIX Expiration","10d","5d", "2d","1d","Today"], // labels for the axes and the series

        ];
        
        //console.log (NumM1Exp0day,NumM1Exp2day,NumM1Exp5day,NumM1Exp10day)

        chartData = [
          ["VIX Expiration","10d","5d", "2d","1d","Today"], // labels for the axes and the series
          
          [null, null, null, null, null, null], // first point
          
          [M1Exp0day, Number(record10day["VIX1"]),Number(record5day["VIX1"]),Number(record2day["VIX1"]),Number(record1day["VIX1"]),Number(record0day["VIX1"])], // first point
          
          [M2Exp0day, Number(record10day["VIX2"]),Number(record5day["VIX2"]),Number(record2day["VIX2"]),Number(record1day["VIX2"]),Number(record0day["VIX2"])], // second point
          
          [M3Exp0day, Number(record10day["VIX3"]),Number(record5day["VIX3"]),Number(record2day["VIX3"]),Number(record1day["VIX3"]),Number(record0day["VIX3"])], // third point
          
          [M4Exp0day, Number(record10day["VIX4"]),Number(record5day["VIX4"]),Number(record2day["VIX4"]),Number(record1day["VIX4"]),Number(record0day["VIX4"])], // fourth point
          
          [M5Exp0day, Number(record10day["VIX5"]),Number(record5day["VIX5"]),Number(record2day["VIX5"]),Number(record1day["VIX5"]),Number(record0day["VIX5"])], // fifth point
          
          [M6Exp0day, Number(record10day["VIX6"]),Number(record5day["VIX6"]),Number(record2day["VIX6"]),Number(record1day["VIX6"]),Number(record0day["VIX6"])], // sixth point    
          
                   
        ];

        // set the data state to the chart data
        setData(chartData);
        } catch (error) {
      console.error(error);
    }
  };
  // use useEffect hook to call the fetch data function when the component mounts
  useEffect(() => {
    fetchData();
  }, []);




  // return the JSX code for the chart component
    return (
        
    
        <Chart
            width={"90vw"}
            height={"60vh"}
            chartType="ComboChart"
            loader={<div>Loading Chart</div>}
            data={data}

            
            options={{
               
                title: "VIX Futures Curve (6 contracts) [Data not updated/Under Construction]" , 
                Position: 'top',
                //titlePosition: 'in', 
                
                titleTextStyle: {
                fontSize: 12,
                color: 'black', 
                fontName: 'Merriweather'
                //bold: true, 
                //italic: false
                },
                backgroundColor: '#edf3f5', 
                
                hAxis: {
                  title: "Expiration Month",
                  titleTextStyle: { color: "#333" },
                  //format: 'MMM' 
                },
                
                vAxis: { 
                  minValue: 0,
                  viewWindow: {min: 10},
                  textPosition: 'in',  //text appears on chart itself
                  gridlines: {
                    strokeDasharray: '3 3'  // Set dashed gridlines
                  }

                },

                chartArea: { alignment: "center", width: "80%", height: "60%" },
                
                isStacked: false, 
                interpolateNulls: false, 
                // add the seriesType and series options here
                
                chartType: "combochart", // set the chart type to combo

                //seriesType: "line", // set the default type to line

                series: {
                  0: { type: "line" , lineWidth: 2, pointSize: 4, pointShape: 'polygon' }, 
                  1: { type: "line" , lineWidth: 2, pointSize: 4, pointShape: 'polygon' }, 
                  2: { type: "line" , lineWidth: 2, pointSize: 4, pointShape: 'polygon' },
                  3: { type: "line" , lineWidth: 2, pointSize: 4, pointShape: 'polygon' },
              
                  4: { type: "area" , lineWidth: 3, pointSize: 7, pointShape: 'diamond', areaOpacity: 0.3 }, 
                },
                
                //switch sides from zscore's color
                colors: ["MediumSeaGreen", "SteelBlue", "MediumPurple", "SandyBrown", "IndianRed"],
                
                
                //colors: ["IndianRed", "SandyBrown", "MediumPurple", "SteelBlue", "MediumSeaGreen"],
                
                /*
                  1: { type: "line" , lineWidth: 2,  pointSize: 3, pointShape: 'diamond' }, 
                  2: { type: "line", lineWidth: 2,  pointSize: 3, pointShape: 'diamond' }, 
                  3: { type: "line" , lineWidth: 2  pointSize: 3, pointShape: 'diamond'}, 
                  4: { type: "line" , lineWidth: 2  pointSize: 3, pointShape: 'diamond'}
                */
                
                /*
                trendlines: {
                  0: {
                    type: 'polynomial',
                    degree: 40,
                    color: '48B8F0',
                    opacity: 1,
                    pointSize: 1,
                    visibleInLegend: false,
                    pointsVisible: true,
                    //pointShape: 'polygon',
                    //pointShape: 'star',
                    //pointShape: 'square',
                  },
                },  
                
                /*
                  5: {
                    type: 'polynomial',
                    degree: 30,
                    color: 'BB453A',
                    opacity: 0.7,
                    pointSize: 1,
                    visibleInLegend: false,
                    pointsVisible: true,
                    //pointShape: 'polygon',
                    //pointShape: 'star',
                    //pointShape: 'square',
                    //pointShape: 'circle',
                    //pointShape: ''square' ',
                    //pointShape: ''diamond' ',

                    
                  },                     
                },
                */

  
                legend: { 
                    position: "bottom", 
                    alignment: "center",
                    textStyle: { fontName: 'Merriweather', fontSize: '10'}, 
                    maxLines: 1,
                    legendToggle: false
                },
                
                curveType: 'function',
                animation: { startup: true, duration: 1000, easing: 'out' }

                }}
                                
        style={{ margin: "1px" }} 
        />
          
            //</ResponsiveContainer>
    );
};

